<template>
  <div>

     <v-container style="max-width: 1200px">
        <h1 style="margin-bottom: 10px">Einstellungen</h1>

        <div style="margin-top: 40px">
          <!-- Prename -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>Username:</b></span>
              </v-col>
              <v-col cols="4">
                <span>{{ user.userName }}</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" v-if="false" @click="changeEntry('setUsername', prenameModel)">bearbeiten</span>
              </v-col>
            </v-row>
          </v-container>

          <!-- Prename -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>Vorname:</b></span>
              </v-col>
              <v-col cols="4">
                <span>{{ user.prename }}</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" v-if="false" @click="changeEntry('setPrename', prenameModel)">bearbeiten</span>
              </v-col>
            </v-row>
          </v-container>

          <!-- Surname -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>Nachname:</b></span>
              </v-col>
              <v-col cols="4">
                <span>{{ user.surname }}</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" v-if="false">bearbeiten</span>
              </v-col>
            </v-row>
          </v-container>

          <!-- Birthday -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>Geburtsdatum:</b></span>
              </v-col>
              <v-col cols="4">
                <span>{{ user.birthDay ? user.birthDay.split('-').reverse().join('.') : '' }}</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" v-if="false">bearbeiten</span>
              </v-col>
            </v-row>
          </v-container>

          <!-- Mail -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>E-Mail-Adresse:</b></span>
              </v-col>
              <v-col cols="4">
                <span>{{ user.workMail }}</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" v-if="false">bearbeiten</span>
              </v-col>
            </v-row>
          </v-container>

          <!-- Passwort -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="4">
                <span><b>Passwort:</b></span>
              </v-col>
              <v-col cols="4">
                <span>**************</span>
              </v-col>
              <v-col cols="4" style="text-align: right">
                <span class="primary--text" style="cursor: pointer" @click="passwordFlag = true">bearbeiten</span>
              </v-col>
            </v-row>
            <v-expand-transition>
              <div v-if="passwordFlag">
                <v-alert type="error" v-model="errorPwd">
                  Etwas ist schiefgelaufen. Überprüfe deine Eingabe und versuche es erneut.
                </v-alert>
                <v-text-field
                  v-model="oldPwd"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Altes Passwort"
                  hint="At least 8 characters"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  v-model="newPwd"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Neues Passwort"
                  hint="At least 8 characters"
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-text-field
                  v-model="newPwdAgain"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Neues Passwort wiederholen"
                  hint="At least 8 characters"
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-btn width="100%" class="primary" @click="saveNewPassword">
                  Speichern
                </v-btn>
              </div>
            </v-expand-transition>
          </v-container>

        </div>
     </v-container>

     <!-- Success Dialog -->
     <v-dialog :fullscreen="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" v-model="success" max-width="815px" @click:outside="closeDialog()">
      <v-card>
        <div style="width: 98%; padding-top: 2%; position: absolute">
          <v-icon color="primary" style="float: right; cursor: pointer" @click="closeDialog()">mdi-close</v-icon>
        </div>
        <div style="width: 100%;" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '10%' : '4%' }">
          <div style="margin: auto; width: 1.5in" :style="{ 'padding-top': ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '5%' : '4%' }">
            <v-icon size="1.5in" color="success">mdi-check-circle</v-icon>
          </div>
        </div>
        <div :style="{ padding: ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '0 5% 2% 5%' : '0 14% 2% 14%' }">
          <v-card-text style="padding: 0">
            <p :class="{ headline: !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) }" class="font-weight-regular" style="text-align: center; width: 100%; margin: 0.4in 0">
              Dein Passwort wurde erfolgreich geändert
            </p>
            <div style="padding: 0 0.2in">
              <v-btn width="100%" @click="closeDialog()" class="success" style="margin-bottom: 0.2in;">Schließen</v-btn>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      oldPwd: '',
      show1: false,
      show2: false,
      errorPwd: false,
      newPwd: '',
      newPwdAgain: '',
      passwordFlag: false,
      success: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    closeDialog () {
      this.success = false
    },
    saveNewPassword () {
      this.errorPwd = false
      if (this.oldPwd === '' || this.newPwd === '' || this.newPwdAgain === '' || this.newPwd !== this.newPwdAgain) {
        window.setTimeout(() => { this.errorPwd = true }, 1000)
      } else {
        axios.patch('/user/changePWD', { oldpwd: this.oldPwd, newpwd: this.newPwd })
          .then(() => {
            this.oldPwd = ''
            this.newPwd = ''
            this.newPwdAgain = ''
            this.passwordFlag = false
            this.success = true
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.errorPwd = true
            } else {
              this.errorPwd = true
            }
          })
      }
    },
    changeEntry (fn, payload) {
      if (payload === '' || payload === null) return

      this.$store.dispatch(fn, payload)
        .then(() => {
          this.success = true
        })
        .catch(() => {
          switch (fn) {
            case 'setPrename': {
              this.prenameError = true
              break
            }
            case 'setSurname': {
              this.surnameError = true
              break
            }
            case 'setBirth': {
              this.birthError = true
              break
            }
            case 'setEmail': {
              this.emailError = true
              break
            }
            case 'setPwd': {
              this.pwdError = true
              break
            }
            default: {
              break
            }
          }
        })
    }
  }
}
</script>
